import React from "react";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@material-ui/core";
import jsPDF from "jspdf";
import "jspdf-autotable";
import GetAppIcon from "@material-ui/icons/GetApp";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setProcedure } from "../../../redux/actions/gpt/esgGptChatsAction";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import CustomAppBar from "../../UI/appBar/appBar";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    // backgroundColor: '#061148', // Blue color
    borderRadius: theme.spacing(1),
    // color: theme.palette.common.white, // White text color
  },
  tableContainer: {
    marginBottom: theme.spacing(2),
    // border: "1px solid #fff", // White border for the whole table
  },
  selectFileButton: {
    marginRight: theme.spacing(1),
    backgroundColor: "#0285A8", // Blue color
    color: theme.palette.common.white, // White text color
    // borderRadius: theme.spacing(1),
    // padding: theme.spacing(1, 2),
    // marginBottom: theme.spacing(2), // Add margin bottom to space out from the table
  },
  tableHeaderCell: {
    fontWeight: "bold", // Make header cells bold
    borderRight: "1px solid #fff", // Add right border to separate columns
    // borderBottom: "1px solid #fff", // Add bottom border to the header row
  },
  tableCell: {
    borderRight: "1px solid #fff", // Add right border to separate columns
  },
}));

const ResponseTable = ({ location }) => {
  const { responseData } = location.state;
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();

  const downloadPDF = () => {
    // Check if responseData and responseData.complianceItems are defined
    if (!responseData || !responseData.complianceItems) {
      console.error("Response data or compliance items are undefined");
      return;
    }

    const pdf = new jsPDF();
    pdf.text("RESPONSE TABLE", 10, 10);

    // Define table headers
    const headers = ["Compliance Item", "Status", "Source"];
    const rows = [headers];

    // Extract data from responseData and add to rows array
    responseData.complianceItems.forEach((item) => {
      const complianceItem = item["Compliance Item"];
      const status = item["Status"];
      const source = item["Source"];
      rows.push([complianceItem, status, source]);
    });

    // Set up table parameters
    const tableProps = {
      startY: 20, // Start position for table
      margin: { top: 20 }, // Margin top
      headStyles: { fillColor: [100, 100, 100] }, // Header background color
      bodyStyles: { textColor: [0, 0, 0] }, // Body text color
    };

    // Add table to PDF
    pdf.autoTable({
      head: [headers],
      body: rows.slice(1), // Exclude headers from body data
      ...tableProps,
    });

    // Save PDF as blob and create a download link
    const blob = pdf.output("blob");
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "compliance_report.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Release the object URL
    URL.revokeObjectURL(url);
  };

  const handleSelectAnotherDocument = () => {
    dispatch(setProcedure(""));
    history.push("/esg_gpt");
  };

  if (!responseData || !responseData.complianceItems) {
    return <h1>No data available</h1>;
  }

  const { complianceItems } = responseData;

  return (
    <Grid container component={Paper} elevation={0} style={{ height: "100%" }}>
      <Grid item md={12} sm={12}>
        <CustomAppBar logOut={false} isOpen={true} />
      </Grid>
      <Grid item md={12} sm={12}>
        <div className={classes.root}>
          <Typography variant="h5" gutterBottom>
            Response Table
          </Typography>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell}>
                    Compliance Item
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    Status
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    Source
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {complianceItems.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.tableCell}>
                      {item["Compliance Item"]}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {item.Status}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {item.Source}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div>
            <Button
              onClick={downloadPDF}
              className={classes.selectFileButton}
              variant="contained"
              color="primary"
              startIcon={<GetAppIcon />}
            >
              Download PDF
            </Button>
            <Button
              onClick={handleSelectAnotherDocument}
              className={classes.selectFileButton}
              variant="contained"
              color="primary"
              startIcon={<AutorenewIcon />}
            >
              Select Another Document
            </Button>
          </div>
        </div>
      </Grid>
      {/* <EsgGptMainLeft width={100}>
      <EsgMOreBUttons />
    </EsgGptMainLeft> */}
    </Grid>
  );
};

export default ResponseTable;
