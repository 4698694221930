import React, { useState, useEffect, useRef } from "react";

import { useHistory } from "react-router-dom";

import { makeStyles, withStyles } from "@material-ui/core/styles";
// import EsgGptMainTitle from '../components/EsgGptMainTitle';

// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// import EsgGptChat from 'risk_assessment\frontend\src\components\widgets\esgGpt\esgGptChat'
// Import the component for /esg_gpt/chatimport ChatPageComponent from

import {
  Button,
  Grid,
  Icon,
  IconButton,
  InputBase,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Slide,
  Typography,
  useTheme,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// import ChatPageComponent from'../../../../../backend/src/modules/ChatPage.js'
import analytics from "../../../images/magpie.png";
import promptIconWhite from "../../../images/promptIcon.png";
import promptIcon from "../../../images/gieom/Prompt Icon.png";
import { Link } from "react-router-dom";
import ResponseTable from "./ResponseTable";
import useHoverEffect from "../../../customHooks/getHoveringEffects";
import { useDispatch, useSelector } from "react-redux";
import {
  getChatData,
  setProcedure,
} from "../../../redux/actions/gpt/esgGptChatsAction";
// import ChatPage from '../../../../../backend/src/modules/ChatPage.js';
// import axios from 'axios';
// import { ThemeProvider, createTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // border: "1px solid #EAEEF5",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.primary.light,

    borderRadius: "8px",
    // backgroundColor: isHovering ? "#EBF1F8" : "#FFFFFF",
    padding: "13px",
    justifyContent: "flex-start",
    width: "49%",
    // marginBottom: "10px",
    cursor: "pointer",
    alignItems: "center",

    backgroundColor: theme.palette.grey[600],

    "&:hover": {
      backgroundColor: "#294E95",
      color: theme.palette.primary.main,
      "& $typography": {
        color: theme.palette.primary.main,
      },
    },
    menuItem: {
      "&:hover": {
        color: "red",
      },
    },
    [theme.breakpoints.down("md")]: {
      width: "49%",
      padding: "10px",
      alignItems: "center",

      "@media (orientation: portrait)": {
        width: "80%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "49%",
      padding: "10px",
      alignItems: "center",

      "@media (orientation: portrait)": {
        width: "90%",
      },
    },
    // [theme.breakpoints.up("xs")]: {
    //   width: "49%",
    //   alignItems: "center",
    // },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      alignItems: "center",
    },
  },
  mainGpt: {
    height: "80vh",
    // flexWrap: "wrap",
    display: "flex",

    // flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // border: "solid",
    [theme.breakpoints.down("md")]: {
      height: "70vh",
      "@media (orientation: portrait)": {
        flexDirection: "column",
      },
    },
    [theme.breakpoints.down("sm")]: {
      height: "65vh",
      flexDirection: "column",
      // padding: "0px 20px",
    },
    // [theme.breakpoints.down("sm")]: {
    [theme.breakpoints.down("xs")]: {
      height: "98%",

      flexDirection: "column",
      padding: "0px 20px",
      // width:'100%'
    },
  },
  typography: {
    ...theme.typography.body1,
    color: theme.palette.primary.blue,
    fontSize: "13px",
  },
  typography2: {
    ...theme.typography.body2,
    color: theme.palette.primary.dark,
    padding: "15px 0px",
  },
  typograph3: {
    ...theme.typography.body1,
    fontSize: "24px",
    paddingBottom: 15,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      paddingTop: "10px",
    },
  },
  iconButton: {
    marginLeft: "auto",
    borderRadius: "200px",
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "5px",
    width: "30px",
    borderColor: theme.palette.secondary.main,
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: "18px",
    fontWeight: 400,
  },
  roboAi: {
    // border: `1px solid ${theme.palette.grey[500]}`,
    marginBottom: "30px",
    height: "100%",
    alignItems: "center",
    // borderRadius: "675px",
  },
  imgContainer: {
    height: "80%",
    marginTop: "5px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      "@media (orientation: portrait)": {
        height: "45%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      height: "30%",
    },
    selectFileButton: {
      backgroundColor: "#2986cc", // Blue color
      color: theme.palette.common.white, // White text color
      borderRadius: "4px", // Add border-radius for rounded corners
      padding: "10px 20px", // Adjust padding as needed
      cursor: "pointer",
    },
    selectFileButton: {
      backgroundColor: "#2986cc", // Blue color
      color: theme.palette.common.white, // White text color
      borderRadius: "4px", // Add border-radius for rounded corners
      padding: "10px 20px", // Adjust padding as needed
      cursor: "pointer",
      marginRight: "10px",
    },
    uploadedFilesDropdown: {
      width: "200px", // Adjust width as needed
      padding: "5px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
  },
}));

function EsgGptMainTitle({ title, newPath, login, procedure }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isHovering, handleMouseEnter, handleMouseLeave } = useHoverEffect();
  const handleChangeRoute = () => {
    console.log("title", title);
    dispatch(getChatData(login.token, title, procedure));
    let path = newPath;
    history.push(path);
    // dispatch(getChatData(login.token, title, procedure));
  };

  return (
    <>
      <Link
        to={newPath}
        style={{ textDecoration: "none", color: "inherit" }}
      ></Link>
      <Grid
        item
        className={classes.root}
        onClick={handleChangeRoute}
        onMouseEnter={() => handleMouseEnter(true)}
        onMouseLeave={() => handleMouseLeave(true)}
      >
        <img
          style={{
            width: "30px",
            height: "24px",
            paddingRight: 8,
          }}
          src={promptIcon}
          alt=""
        />
        <Typography className={classes.typography}>{title}</Typography>
      </Grid>
    </>
  );
}

function EsgGptMainRight() {
  // const someCondition = useEffect();
  const classes = useStyles();
  const { login, esgGptReducer } = useSelector((state) => state);
  const [isProcedure, setIsProcedure] = useState(!esgGptReducer.procedure);
  const [UploadedFiles, setUploadedFiles] = useState([]);
  const [uploadMessage, setUploadMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(UploadedFiles[0] || "");
  const [selectedType, setSelectedType] = useState("");
  const [fileTags, setFileTags] = useState({});
  const [selectedDoc, setSelectedDoc] = useState("");
  const [responseData, setResponseData] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState("");
  const [data, setData] = useState(null);
  //   const availableDatabases = ["Document 1", "Document 2"];
  const history = useHistory();

  useEffect(() => {
    const fetchUploadedFiles = async () => {
      try {
        const response = await fetch("https://magpie7.gieom.com/get-upload");
        if (!response.ok) {
          throw new Error("Failed to fetch uploaded files");
        }
        const data = await response.json();
        setUploadedFiles(data.files);
      } catch (error) {
        console.error("Error fetching uploaded files:", error);
      }
    };

    fetchUploadedFiles();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       if (!selectedDocument) {
  //         // If no document is selected, return without fetching data
  //         return;
  //       }

  //       const response = await fetch("https://magpie7.gieom.com/chat_model", {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({ document_name: selectedDocument }), // Use the selected document name
  //       });

  //       if (!response.ok) {
  //         throw new Error('Failed to fetch data');
  //       }

  //       const data = await response.json();
  //       console.log("Esgmainright", data);
  //       setResponseData(data);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData(); // Call fetchData initially

  // }, [selectedDocument]);

  const handleDocumentSelect = async (event) => {
    const documentName = event.target.value; // Get the selected document name
    setSelectedDocument(documentName);
    console.log("documentName", documentName);
    try {
      const response = await fetch("https://magpie7.gieom.com/chat_model", {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Specify content type
        },
        body: JSON.stringify({ document_name: documentName }), // Pass the selected document name in the request body
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      console.log("Esgmainright", data);
      setResponseData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch("http://localhost:8000/chat_model", {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({ "type": "string", "db_name": "string" }),
  //       });

  //       if (!response.ok) {
  //         throw new Error('Failed to fetch data');
  //       }

  //       const data = await response.json();
  //       setResponseData(data);
  //       // console.log("data",data)
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();

  //   return () => {
  //     // Cleanup function if needed
  //   };
  // }, [selectedDocument]);

  // const handleDocChange = async (event) => {
  //     setSelectedDoc(event.target.value);
  const handleGoButtonClick = () => {
    // Check if responseData is not null before navigating
    if (responseData) {
      // Trigger the exact logic you want to execute when the "Go" button is clicked
      // For example, you can navigate to a specific route or perform some action
      history.push("/esg_gpt/chat", { responseData }); // Pass responseData as route state
      console.log("QQ", responseData);
      console.log("Go button clicked!");
    }
  };
  // const handleDocumentSelect = async (event) => {
  //   const documentName = event.target.value; // Get the selected document name
  //   setSelectedDocument(documentName);

  //   try {
  //       const response = await fetch("http://localhost:8000/chat_model", {
  //           method: 'POST',
  //           headers: {
  //               'Content-Type': 'application/json', // Specify content type
  //               // Add any other headers if needed
  //           },
  //           body: JSON.stringify({ documentName }), // Pass the selected document name in the request body
  //       });

  //       if (!response.ok) {
  //           throw new Error('Failed to fetch data');
  //       }

  //       const data = await response.json();
  //       console.log("Esgmainright", data);
  //       setResponseData(data);

  //   } catch (error) {
  //       console.error('Error fetching data:', error);
  //   }
  // };

  //     try {
  //         const response = await fetch("http://localhost:8000/chat_model",{
  //             method: 'POST',
  //             headers: {
  //             'Content-Type': 'application/json', // Specify content type
  //     // Add any other headers if needed
  //   },
  //   body: JSON.stringify({"type": "string",
  //   "db_name": "string"}),
  //         });

  //       // Make the API call with the selected document
  //     //   const response = await fetch(`http://164.52.219.14:8530/${event.target.value}`);
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch data');
  //       }

  //       const data = await response.json();
  //       console.log("Esgmainright",data)
  //       setResponseData(data);
  //       // Navigate to the next page with the fetched data
  //     //   history.push(
  //     //     "/esg_gpt/chat",
  //     //     { data : data,
  //     //         message : "msg"
  //     //   });
  // //        history.push('/esg_gpt/chat', {
  // //         data
  // //     //      {
  // //     //     key1: 'value1',
  // //     //     key2: 'value2',
  // //     //     // Add more data as needed
  // //     // }
  // // });

  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }

  //   const magPieProcedures = [
  //     "Receive and verify LC Application and supporting documents",
  //     "Limit Check and Approval",
  //     "Margin Money & Import Licence",
  //     "System Data Entry & Authorization",
  //     "Issue Reimbursement Authority",
  //     "Insurance Policy / Certificate",
  //     "Declined / Rejected LC Applications",
  //   ];
  // if (someCondition) {
  //   return <div>Case 1</div>;
  // }
  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      "&:focus": {
        borderRadius: 4,

        // borderColor: '#80bdff',
        // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
      "&:hover": {
        "&& fieldset": {
          border: "3px solid green",
        },
      },
    },
  }))(InputBase);
  const Procedure = ({ procedure, isProcedure, setIsProcedure }) => {
    const dispatch = useDispatch();

    const [uploadMessage, setUploadMessage] = useState("");
    const [isUploadSuccess, setIsUploadSuccess] = useState(false);
    const history = useHistory();

    const fileInputRef = useRef(null);

    const handleFileChange = async (event) => {
      const file = event.target.files[0];
      const tagName = file.name;
      setSelectedFile(file);
      setUploadedFiles((prevFiles) => [...prevFiles, tagName]);

      // Create a FormData object to send the file to the backend
      const formData = new FormData();
      // formData.append('type', selectedType);
      formData.append("file", file);
      console.log("formdat", formData);

      try {
        // Send the file to the backend using fetch or your preferred HTTP library
        const response = await fetch(
          "https://magpie7.gieom.com/upload?document_type=" +
            encodeURIComponent(selectedType),
          {
            method: "POST",
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const responseData = await response.json(); // Use responseData instead of response.data
        console.log("Esgmainright", responseData);
        setResponseData(responseData);

        // Parse the JSON response
        const data = await response.json();

        // Update the file tags with the new file name and tag
        setFileTags((prevTags) => ({
          ...prevTags,
          [data.fileName]: tagName,
        }));

        // const data = await response.json();
        // console.log("File uploaded successfully:", data);
        const updatedFilesResponse = await fetch(
          "https://magpie7.gieom.com/get-upload"
        );
        const updatedFilesData = await updatedFilesResponse.json();
        setUploadedFiles(updatedFilesData.files);
        setUploadMessage("File uploaded successfully");
        setIsUploadSuccess(true);

        // Update the list of uploaded files
        setUploadedFiles((prevFiles) => [...prevFiles, data.fileName]);
        console.log("dd", UploadedFiles);
      } catch (error) {
        console.error("Error uploading file:", error);
        setUploadMessage("Error uploading file. Please try again.");
      }
    };
    // Create a new function to handle selection change in the dropdown and navigate
    const handleFileSelectionChange = (e) => {
      const selectedFile = e.target.value;
      setSelectedFile(selectedFile);
      dispatch(setProcedure(e.target.value));
      setIsProcedure(false);

      // Navigate to the next page with the selected file
      // if(selectedFile!==undefined)
      //  history.push("/esg_gpt/chat");
    };

    const handleButtonClick = () => {
      fileInputRef.current.click();
    };
    const handleTypeChange = (event) => {
      setSelectedType(event.target.value);
    };
    <Procedure
      procedure={esgGptReducer.procedure}
      isProcedure={isProcedure}
      setIsProcedure={setIsProcedure}
      responseData={responseData} // Pass responseData to the Procedure component
    />;

    return (
      <Grid container md={12}>
        <Grid item md={10}>
          <div>
            <Typography
              variant="h4"
              style={{ textAlign: "center", marginBottom: 20 }}
            >
              MAGPIE
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Select
                value={selectedType}
                onChange={handleTypeChange}
                displayEmpty
                fullWidth
                variant="outlined"
              >
                <MenuItem value="">Select type</MenuItem>
                <MenuItem value="Vendor Policy">Vendor Policy </MenuItem>
                <MenuItem value="Vendor contracts">Vendor Contracts</MenuItem>
              </Select>
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#0285A8",
                  color: "#FFFFFF",
                  marginLeft: "10px",
                }}
                onClick={handleButtonClick}
              >
                Upload
              </Button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div>{uploadMessage}</div>
                {/* Display updated uploaded files dropdown */}
                <div>
                  <Typography className={classes.typography2}>
                    Uploaded Files:
                  </Typography>

                  {/* <Select
                      value={selectedDocument}
                      onChange={handleDocumentSelect}
                      displayEmpty
                      fullWidth
                      variant="outlined"
                    >
                      <MenuItem value="">Select Document</MenuItem>
                      <MenuItem value="document_1">Document 1</MenuItem>
                      <MenuItem value="document_2">Document 2</MenuItem>
  </Select>*/}
                  <Select
                    value={selectedDocument}
                    onChange={handleDocumentSelect} // Attach the function to the onChange event
                    displayEmpty
                    fullWidth
                    variant="outlined"
                  >
                    <MenuItem value="">Select Document</MenuItem>
                    {UploadedFiles.map((file, index) => (
                      <MenuItem key={index} value={file}>
                        {file}
                      </MenuItem>
                    ))}
                  </Select>

                  {/* <Select
                              value={selectedFile}
                              onChange={(e) => setSelectedFile(e.target.value)}
                              displayEmpty
                              fullWidth
                              variant="outlined"
                            >
                              <MenuItem value="">Select Document</MenuItem>
                              {UploadedFiles.map((file, index) => (
                                <MenuItem key={index} value={file}>
                                  {file}
                                </MenuItem>
                              ))}
                            </Select> */}
                  {/* <Select
                            value={selectedFile}
                            onChange={(e) => handleFileSelectionChange(e)}
                            displayEmpty
                            fullWidth
                            variant="outlined"
                          >
                            <MenuItem value="">Select Document</MenuItem>
                            {UploadedFiles.map((file, index) => (
                              <MenuItem key={index} value={file}>
                                {file}
                              </MenuItem>
                            ))}
                          </Select> */}
                </div>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: "#0285A8", color: "#FFFFFF" }}
                  onClick={handleGoButtonClick}
                >
                  Go
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container md={12} className={classes.mainGpt}>
      <Grid
        md={10}
        style={{
          display: "flex",
          alignItems: "center",
          height: "50vh",
          borderRadius: 10,
          // border: "1px solid #2986cc",
          justifyContent: "space-around",
        }}
      >
        <Grid item md={3} sm={3} className={classes.imgContainer}>
          <img src={analytics} alt="analytics" className={classes.roboAi} />
        </Grid>
        {isProcedure ? (
          <Procedure
            procedure={esgGptReducer.procedure}
            isProcedure={isProcedure}
            setIsProcedure={setIsProcedure}
          />
        ) : (
          <Slide direction="down" in={!isProcedure} mountOnEnter unmountOnExit>
            <Grid
              item
              md={8}
              sm={8}
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {/* <NextPageComponent login={login} esgGptReducer={esgGptReducer} /> */}
            </Grid>
          </Slide>
          // history.push(http://164.52.219.14:8530/chat_model);
        )}
      </Grid>
    </Grid>
  );
}

export default EsgGptMainRight;
