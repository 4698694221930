import React, { useCallback, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
import { ListItem } from "../EsgGptCompanyList";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import LaunchIcon from "@material-ui/icons/Launch";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import KeyboardArrowUpOutlinedIcon from "@material-ui/icons/KeyboardArrowUpOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import EsgGptCompanyInfo, {
  HeadingCard,
} from "./EsgGptCompany/EsgGptCompanyInfo";
import {
  TopicHeader,
  TopicData,
  data,
} from "../../esgCompanyProfile/esgComponents";
import ScoreChart from "../../esgCompanyProfile/ScoreChart";
import { OverallRiskCard } from "../../esgCompanyProfile/overallProfile";
import {
  improvementAreas,
  riskArea,
  strongAreas,
} from "../../../../utils/riskUtils/riskUtils";

import star from "../../../../images/IconBlue.png";
import thumbUp from "../../../../images/thumbUp.svg";
import contro_1 from "../../../../images/contro_1.svg";

import contro_2 from "../../../../images/contro_2.svg";

import thumbDown from "../../../../images/thumbDown.svg";
import more from "../../../../images/more.svg";
import share from "../../../../images/share.svg";
import windmill from "../../../../images/windmill.png";
import hcltech from "../../../../images/companyB.svg";
import hcl from "../../../../images/companyC.svg";
import airTable from "../../../../images/companyD.svg";
import freetrail from "../../../../images/freetrail.png";
import docIcon from "../../../../images/gieom/docIcon.png";
import tickGreen from "../../../../images/gieom/tickGreen.png";
import neutralYellow from "../../../../images/gieom/neutralYellow.png";
import closeRed from "../../../../images/gieom/closeRed.png";
import moreButton from "../../../../images/gieom/More Button.png";
import AutorenewIcon from "@material-ui/icons/Autorenew";

import PercentageDoughnutChart from "../../../UI/percentageDoughnut.js/percentageDoughnutChart";
import Charts from "./EsgGptCompany/SampleCharts";
import EsgGptChatMessageLayout from "../esgGptChat/EsgGptChatMessageLayout";

import {
  getOverallData,
  getScores,
  getPeerScores,
} from "../../../../redux/actions/riskEvaluator/riskEvaluatorActions";
import EsgScore from "../../esgCompanyProfile/esgScore";
import CustomRange from "../../../UI/CustomRange/CustomRange";
import CustomCompaniesRange from "../../../UI/CustomRange/customCompaniesRange";
// import { ListItem } from "../EsgGptCompanyList";
import { Button, Divider, Paper } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import jsPDF from "jspdf";
import CustomButton from "../../../UI/button/button";
import { setChatData, setProcedure } from "../../../../redux/actions/gpt/esgGptChatsAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: "red",
    // color: theme.palette.common.black,
    ...theme.typography.body1,
    // fontWeight: 500,
    color: theme.palette.primary.dark,
    backgroundColor:
      theme?.palette?.type === "dark" && theme.palette.background.default,
  },
  body: {
    ...theme.typography.body2,
    color: theme.palette.primary.main,

    border:
      theme.palette.type === "dark"
        ? `1px solid ${theme.palette.grey[300]}`
        : `1px solid ${theme.palette.primary.light}`,

    // fontSize: 14,
    // fontWeight: 500,
    // color: "#15314E",
  },
}))(TableCell);

function createData(srNo, name, calories) {
  return { srNo, name, calories };
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "75vh",
    [`${theme.breakpoints.up("lg")} `]: {
      height: "76vh",
      // border:"solid yellow"
      // width: "95vw",
      // marginLeft: "30px",
    },
    [`${theme.breakpoints.up("xl")} `]: {
      height: "82vh",
      // border:"solid red"
      // width: "95vw",
      // marginLeft: "30px",
    },

    width: "100%",
    overflow: "scroll",
    marginBottom: "1%",
    // border:"solid",
    backgroundColor: theme.palette.background.paper,
    padding: "1px",
  },
  table: {
    // minWidth: 700,
    // marginTop: "10px",
    width: "100%",
    height: "100%",
    // border:"1px solid"
  },
  tableCell: {
    // padding: "15px",
  },
  titleRow: {
    // border:"solid",
    // backgroundColor: "#F6F6F6",
  },
  typography: {
    ...theme.typography.body1,
    color: theme.palette.primary.main,
    padding: "19px 20px",
    // border:"solid",
    // textAlign:"center"
  },
  mainHeadinBackground: {
    backgroundColor:
      theme?.palette?.type === "dark"
        ? theme.palette.background.default
        : theme.palette.background.paper,
    width: "100%",

    borderBottom:
      theme?.palette?.type !== "dark" &&
      `1px solid ${theme.palette.primary.light}`,

    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "10px",
  },
  typography2: {
    ...theme.typography.h5,
    color: theme.palette.primary.main,
    padding: "10px",
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  borderPrimary: {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
  },

  typoColorSecondary: {
    color: theme.palette.primary.dark,
  },
  backgroundColorPrimary: {
    backgroundColor: theme.palette.background.paper,
  },
  iconBackground: {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.secondary.main
        : theme.palette.background.paper,
  },
  secondaryColorMain: {
    color:
      theme.palette.type === "dark"
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
  },
  iconBackground2: {
    // border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  suggestion: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#294E95",
    },
  },
}));

export function BlueBackgroundCard({ heading }) {
  const classes = useStyles();
  return (
    <div className={classes.mainHeadinBackground}>
      <Typography className={classes.typography} style={{}}>
        {heading}
      </Typography>
    </div>
  );
}

const defaultRow = [
  "Topic",
  "Current Financial Year",
  "Previous Financial Year",
];

const defaultRow1 = ["Topic", "Year ‘23", "Year '22"];

const rows = [
  createData("Total Electricity Consumption", 11572, 13940),
  createData("Total Fuel Consumption", 1496, 1090),
  createData("Energy consumption through other sources", 40474, 41477),
  createData("Total energy consumption", 53542, 56507),
  createData(
    "Energy intensity per USD of turnover (Total energy consumption/ turnover in rupees)",
    0.2,
    0.2
  ),
  createData(
    "Energy intensity (optional) the relevant metric may be selected by the entity.",
    0.5,
    0.7
  ),
];

const energyRows = [
  createData("Total Electricity Consumption", 11572, 13940),
  createData("Total Fuel Consumption", 1496, 1090),
  createData("Energy consumption through other sources", 40474, 41477),
  createData("Total energy consumption", 53542, 56507),
  createData(
    "Energy intensity per USD of turnover (Total energy consumption/ turnover in USD)",
    0.2,
    0.2
  ),
  createData(
    "Energy intensity (optional) the relevant metric may be selected by the entity.",
    0.5,
    0.7
  ),
];

const waterRows = [
  createData("Surface water", 12370, 9516),
  createData("Groundwater", 0, 0),
  createData("Third party water", 15384, 13716),
  createData("Seawater / desalinated water", 0, 0),
  createData("Others", 30466, 2551),
  createData("Total volume of water withdrawal(in kilolitres)", 2712, 25183),
  createData("Total volume of water consumption (in kilolitres)", 42231, 38881),
  createData(
    "Water intensity per USD of turnover (Water consumed / turnover)",
    0.4,
    0.4
  ),
  createData(
    "Water intensity (optional) – the relevant metric may be selected by the entity",
    0.5,
    0.1
  ),
];

export function ChatTable({
  row,
  subRow,
  rowsData,
  desc,
  desc2,
  desc3,
  ...props
}) {
  const classes = useStyles();
  const theme = useTheme();
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const downloadPDF = (ref) => {
    const input = document.getElementById("pdf-content");
    const pdf = new jsPDF({
      orientation: "landscape",
      format: "a4",
    });
    pdf.html(input, {
      html2canvas: {
        // reduce scale to male the element smaller
        // scale: 0.2,  - for portrait
        scale: 0.222, //- for ladscape
        letterRendering: true,
      },
      callback: function (pdf) {
        pdf.save("download.pdf");
      },
      x: 2,
      y: 4,
    });
  };

  return (
    <>
      {desc && (
        <Typography
          className={classes.typoColorSecondary}
          style={{
            fontSize: "14px",
            // fontWeight: secondaryHead ? 400 : 700,
            // color: "#242424",
            padding: "15px",
            lineHeight: "20px",
            fontFamily: "Lato",
          }}
        >
          {desc}
        </Typography>
      )}

      <TableContainer
        id="pdf-content"
        ref={tableRef}
        style={{
          // padding: "3px",
          height: "100%",
          border:
            theme.palette.type === "dark" &&
            `1px solid ${theme.palette.grey[300]}`,
          borderRadius: "8px",
        }}
        className={classes.backgroundColorPrimary}
        component={Paper}
        elevation={0}
      >
        <Table className={classes.table} aria-label="customized table">
          {/* <TableHead> */}
          <TableRow>
            {row?.map((r) => (
              <StyledTableCell
                style={{ textTransform: "capitalize", fontWeight: 500 }}
                className={classes.tableCell}
              >
                {r}
              </StyledTableCell>
            ))}
          </TableRow>

          {subRow && subRow?.length > 0 && (
            <TableRow>
              {subRow?.map((r) => (
                <StyledTableCell
                  style={{
                    textTransform: "capitalize",
                    fontWeight: 500,
                  }}
                  className={classes.tableCell}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      justifyContent: "space-between",
                    }}
                  >
                    {Array.isArray(r)
                      ? r.map((sr) => (
                          <div
                            style={{
                              textTransform: "capitalize",
                              fontWeight: 500,
                            }}
                            className={classes.tableCell}
                          >
                            {sr}
                          </div>
                        ))
                      : r}
                  </div>
                </StyledTableCell>
              ))}
            </TableRow>
          )}
          {/* </TableHead> */}
          <TableBody>
            {rowsData?.map((rows, index) => {
              let valuesArray = Object.keys(rows);
              return (
                <TableRow key={rows.name} className={classes.titleRow}>
                  {valuesArray.map((v, i) =>
                    typeof rows[v] !== "object" ? (
                      <StyledTableCell
                        className={classes.tableCell}
                        style={{ textTransform: "capitalize" }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {v === "column1" && row?.length === 1 ? (
                            <img
                              style={{
                                width: 22,
                                height: 22,
                                marginRight: 5,
                              }}
                              src={docIcon}
                              alt=""
                            />
                          ) : (
                            ""
                          )}
                          {`${rows[v]}`}
                        </div>
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell className={classes.tableCell}>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            // alignItems: "center",
                          }}
                        >
                          {Object.keys(rows[v]).map((obj, i) => {
                            return (
                              obj && (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>
                                    {i === 0 && (
                                      <img
                                        style={{ width: 20, height: 20 }}
                                        src={
                                          rows[v]?.[obj] === "Completed"
                                            ? tickGreen
                                            : rows[v]?.[obj] === "Under Process"
                                            ? neutralYellow
                                            : rows[v]?.[obj] ===
                                                "Not Started" && closeRed
                                        }
                                        alt="-"
                                      />
                                    )}
                                  </div>
                                  <div style={{ padding: "5px" }}>
                                    <Typography
                                      style={{ wordBreak: "break-all" }}
                                    >
                                      {rows[v]?.[obj]}
                                    </Typography>
                                  </div>
                                </div>
                              )
                            );
                          })}
                        </div>
                      </StyledTableCell>
                    )
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: 15,
        }}
      >
        <div style={{ display: "flex", alignItems: "center", paddingLeft: 15 }}>
          <img src={moreButton} alt="" style={{ width: 25 }} />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CustomButton
            variant="contained"
            color="primary"
            style={{
              // fontSize: "18px",
              cursor: "pointer",
              textTransform: "none",
              // display: "flex",
              // alignItems: "center",
              padding: "5px 10px 5px 8px",
              marginRight: 15,
            }}
            onClick={() => downloadPDF(tableRef)}
          >
            <GetAppIcon
              style={{
                // fontSize: "18px",

                cursor: "pointer",
                color: "#FFFF",
                float: "right",
                lineHeight: "20px",
                fontFamily: "Lato",
                marginRight: 5,
                fontSize: 18,
              }}
            />
            Download
          </CustomButton>
          <CustomButton
            variant="contained"
            color="primary"
            style={{
              // fontSize: "18px",
              cursor: "pointer",
              textTransform: "none",
              display: "flex",
              alignItems: "center",
              padding: "5px 10px 5px 8px",
              marginRight: 15,
            }}
            onClick={() => {
              dispatch(setProcedure(""));
              history.push('/esg_gpt')
              // dispatch(setChatData([]))
            }}
          >
            <AutorenewIcon
              style={{
                // fontSize: "18px",

                cursor: "pointer",
                color: "#FFFF",
                float: "right",
                lineHeight: "20px",
                fontFamily: "Lato",
                marginRight: 5,
                fontSize: 18,
              }}
            />
            Select Another Procedure
          </CustomButton>
        </div>
      </div>
      {desc2 && (
        <Typography
          className={classes.typoColorSecondary}
          style={{
            fontSize: "14px",
            // fontWeight: secondaryHead ? 400 : 700,
            // color: "#242424",
            padding: "15px",
            lineHeight: "20px",
            fontFamily: "Lato",
          }}
        >
          {desc2}
        </Typography>
      )}
      {desc3 && (
        <Typography
          className={classes.typoColorSecondary}
          style={{
            fontSize: "14px",
            // fontWeight: secondaryHead ? 400 : 700,
            // color: "#242424",
            padding: "15px",
            lineHeight: "20px",
            fontFamily: "Lato",
          }}
        >
          {desc3}
        </Typography>
      )}
    </>
  );
}

export function KpiPresentation({
  chartsArr,
  width = "100%",
  hide = false,
  row,
  rowsData,
  headerRow = defaultRow,
}) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Paper
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "10px",

        flexWrap: "wrap",
        gap: "5px",
      }}
      elevation={0}
      className={classes.backgroundColorPrimary}
    >
      {chartsArr.map((v) => {
        return (
          <Paper
            style={{
              width: width === "67%" ? "31.8%" : "32.8%",
              // padding: "15px",
              height: chartsArr.length === 1 ? "600px" : "300px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              alignSelf: "stretch",
              borderRadius: "8px",
              border:
                theme.palette.type === "dark" &&
                `1px solid ${theme.palette.grey[300]}`,

              // backgroundColor: "#FEFEFE",
            }}
            className={classes.backgroundColorPrimary}
            elevation={0}
          >
            <HeadingCard
              primaryHead={v}
              hideBorder={true}
              // borderRadius={"8px"}
            />

            {/* <Divider
            className={classes.borderPrimary}
            style={{ width: "100%" }}
          /> */}

            <Charts length={chartsArr.length} />
          </Paper>
          // <Paper
          //   className={classes.backgroundColorPrimary}
          //   elevation={0}
          //   style={{ width: width ? "32%" : "40%", height: "300px" }}
          // >
          //   <Charts />
          // </Paper>
        );
      })}

      {!hide && (
        <Paper
          elevation={0}
          className={classes.backgroundColorPrimary}
          style={{ width, height: chartsArr.length === 1 && "600px" }}
        >
          <ChatTable row={headerRow} rowsData={row} />
        </Paper>
      )}
    </Paper>
  );
}

export function EsgInsightCard({ insight = [] }) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Paper
      style={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: "5px",
        marginTop: "10px",
        // borderBottom: `1px solid ${theme.palette.primary.light}`,

        // justifyContent:"space-between"
      }}
      className={classes.backgroundColorPrimary}
      elevation={0}
    >
      {/* <OverallRiskCard
        // topic={topic.topic}
        // heading={topic.heading}
        description={insight}
        gpt={true}
      /> */}
      {insight.map((topic) => (
        <OverallRiskCard
          topic={topic.topic}
          heading={topic.heading}
          description={topic.description}
        />
      ))}
    </Paper>
  );
}

export function EsgInsightMain({ title, area, color, children }) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div>
      <HeadingCard primaryHead={title} type={theme.palette.type}>
        <IconButton
          variant="outlined"
          className={classes.iconBackground2}
          style={{
            border: `1px solid ${color}`,
            backgroundColor: color,
            marginLeft: "10px",
            padding: "5px",

            borderRadius: "200px",
          }}
          size="small"
        >
          {children}
        </IconButton>
      </HeadingCard>

      <EsgInsightCard insight={area} />
    </div>
  );
}

export function MoreActionButtons() {
  return (
    <div
      style={{
        // width: "300px",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        // border:"solid"
      }}
    >
      <IconButton
        variant="contained"
        style={{
          // borderColor: "#FBFBFB",
          // backgroundColor: "#EDF2F3",
          marginLeft: "10px",
          padding: "5px",

          borderRadius: 5,
        }}
        size="small"
      >
        <img src={thumbUp} style={{ cursor: "pointer" }} alt="pdf icon" />
      </IconButton>
      <IconButton
        variant="contained"
        style={{
          // borderColor: "#FBFBFB",
          // backgroundColor: "#EDF2F3",
          marginLeft: "10px",
          padding: "5px",

          borderRadius: 5,
        }}
        size="small"
      >
        <img src={thumbDown} style={{ cursor: "pointer" }} alt="pdf icon" />
      </IconButton>
      <IconButton
        variant="contained"
        style={{
          // borderColor: "#FBFBFB",
          // backgroundColor: "#EDF2F3",
          marginLeft: "10px",
          padding: "5px",

          borderRadius: 5,
        }}
        size="small"
      >
        <img src={more} style={{ cursor: "pointer" }} alt="pdf icon" />
      </IconButton>
      <IconButton
        variant="contained"
        style={{
          // borderColor: "#FBFBFB",
          // backgroundColor: "#EDF2F3",
          marginLeft: "10px",
          padding: "5px",

          borderRadius: 5,
        }}
        size="small"
      >
        <img src={share} style={{ cursor: "pointer" }} alt="pdf icon" />
      </IconButton>
    </div>
  );
}
export const linkData = [
  {
    type: "The Top 6 Environmental Issues in Business",
    logo: windmill,
    topic: "Company A",
    url: "https://www.knowesg.com/investors/hcltech-earns-global-recognition-for-esg-performance-13032023",
  },
  {
    type: "Responsible Sourcing",
    logo: hcltech,
    topic: "Company B",
    url: "https://www.freepressjournal.in/business/hcltech-receives-top-global-recognition-for-its-esg-performance",
  },
  {
    type: "Annual Report",
    logo: hcl,
    topic: "Company C",
    url: "https://www.knowesg.com/investors/hcltech-earns-global-recognition-for-esg-performance-13032023",
  },
  {
    type: "The Top 6 Environmental Issues in Business",
    logo: airTable,
    topic: "Company D",
    url: "https://www.luxotica.tech.com/luxotica-annual-report-2022/sustainability.php",
  },
];

export function RefrenceLinkList({ linkArr }) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <List style={{ height: "300px", overflow: "scroll", width: "100%" }}>
      {linkArr.map((v) => {
        return (
          <ListItem style={{ padding: 5 }}>
            <ListItemAvatar>
              <img
                src={v?.logo}
                style={{ padding: "0px 8px 0px 5px" }}
                width={"75px"}
                height={"100%"}
                alt="star"
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  className={classes.typoColorSecondary}
                  style={{
                    fontSize: 13,
                    fontWeight: 500,
                    // color: "#000000",
                  }}
                >
                  {v?.topic}
                </Typography>
              }
              secondary={
                <Typography
                  variant="body2"
                  className={classes.typoColorSecondary}
                  style={{
                    fontSize: 11,
                    paddingTop: 5,
                    // color: "#6F7170",
                    width: "95%",
                    overflow: "hidden",
                  }}
                >
                  {v?.url}
                </Typography>
              }
            />
            <ListItemAvatar style={{ padding: 5 }} align="center">
              <IconButton style={{ padding: 5 }} edge="end" aria-label="delete">
                <LaunchIcon
                  onClick={() => window.open(v.url)}
                  className={classes.typoColorSecondary}
                />
              </IconButton>
            </ListItemAvatar>
          </ListItem>
        );
      })}
    </List>
  );
}

// const chatAutoSuggestion = [
//   "How can Lenovo tools meet Customer's ESG goals and by how much?",
//   "What all are the Lenovo's Sustainability solutions? ",
//   "Which Lenovo tools from Customer's purchases boost sustainability?",
//   "What is Customer's current GHG emissions ?",
//   "Can you get me Customer's Purchase history ?",
//   "How can Lenovo aid Customer's sustainability efforts?",
// ];

export function ChatPlayGround() {
  const classes = useStyles();
  const [chatInitiate, setChatInitiate] = React.useState(true);
  const handleChat = () => {
    setChatInitiate(true);
  };
  // console.log(chatInitiate);
  const { newOrganization } = useSelector((state) => state.esgGptReducer);

  useEffect(() => {
    // setChatInitiate(true);
  }, [newOrganization?.persona]);
  // console.log("out", newOrganization);

  const returnSuggestions = () => {
    const chatAutoSuggestion1 = [
      "Can you give details for purchasing 1000 Lenovo laptops?",
      "What all are the Lenovo's Sustainability solutions? ",
      "Which Lenovo tools from Customer's purchases boost sustainability?",
      "Can you get me Customer's Purchase history ?",
      "How can Lenovo aid Customer's sustainability efforts?",
      "What is Customer's current GHG emissions ?",
    ];
    const chatAutoSuggestion2 = [
      "Can you give details for purchasing 50 Lenovo laptops?",
      "What all are the Lenovo's Sustainability solutions? ",
      "Which Lenovo tools from Customer's purchases boost sustainability?",
      "Can you get me Customer's Purchase history ?",
      "How can Lenovo aid Customer's sustainability efforts?",
      "What is Customer's current GHG emissions ?",
    ];

    const chatAutoSuggestion3 = [
      "Can you share purchase history?",
      "What all are the Lenovo's Sustainability solutions? ",
      "Which Lenovo tools from Customer's purchases boost sustainability?",
      "Can you get me Customer's Purchase history ?",
      "How can Lenovo aid Customer's sustainability efforts?",
      "What is Customer's current GHG emissions ?",
    ];
    const chatAutoSuggestion4 = [
      "Can you share purchase history?",
      "What all are the Lenovo's Sustainability solutions? ",
      "Which Lenovo tools from Customer's purchases boost sustainability?",
      "Can you get me Customer's Purchase history ?",
      "How can Lenovo aid Customer's sustainability efforts?",
      "What is Customer's current GHG emissions ?",
    ];
    const gieomchatAutoSuggestion = [
      "Can you share purchase history?",
      "What all are the Lenovo's Sustainability solutions? ",
      "Which Lenovo tools from Customer's purchases boost sustainability?",
      "Can you get me Customer's Purchase history ?",
      "How can Lenovo aid Customer's sustainability efforts?",
      "What is Customer's current GHG emissions ?",
    ];

    if (newOrganization?.persona === 1) return chatAutoSuggestion1;
    if (newOrganization?.persona === 2) return chatAutoSuggestion2;
    if (newOrganization?.persona === 3) return chatAutoSuggestion3;
    if (newOrganization?.persona === 4) return chatAutoSuggestion4;
    if (newOrganization?.persona === 5) return gieomchatAutoSuggestion;
    return [];
  };

  return (
    <>
      {!chatInitiate && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            justifyContent: "space-between",
          }}
        >
          {returnSuggestions().map((suggestion) => (
            <div
              style={{
                width: "32.5%",
                textAlign: "center",
                cursor: "pointer",
                // border: "solid",
              }}
              onClick={handleChat}
              className={classes.suggestion}
            >
              <Typography
                className={classes.typoColorSecondary}
                style={{
                  fontSize: "14px",
                  // fontWeight: secondaryHead ? 400 : 700,
                  // color: "#242424",
                  padding: "15px",
                  lineHeight: "20px",
                  fontFamily: "Lato",
                }}
              >
                {suggestion}
              </Typography>
            </div>
          ))}
        </div>
      )}
      {chatInitiate && <EsgGptChatMessageLayout />}
    </>
  );
}

function EsgGptCompanyRight() {
  const theme = useTheme();
  const classes = useStyles();
  const login = useSelector((state) => state.login);

  const riskEvaluator = useSelector((state) => state.riskEvaluator);

  const { scoreObject, esgScore, esgcScore } = { ...riskEvaluator?.scores };

  const { environment, social, governance } = { ...scoreObject };

  const { final_esg_score, max_esg_score, min_esg_score } = {
    ...riskEvaluator?.peerScores,
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    // dispatch(getScores(login.token));
    // dispatch(getOverallData(login.token));
    // dispatch(getPeerScores(login?.token));
  }, []);

  return (
    <Paper elevation={0} className={classes.root}>
      <div
        style={{
          width: "100%",
        }}
      >
        <EsgGptCompanyInfo esgScore={esgScore} esgCScore={esgcScore} />
      </div>

      <BlueBackgroundCard heading={"ESG Profile With ESG Score"} />

      <div
        style={{
          // height: "95%",
          width: "100%",
          padding: "15px 0px",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: "10px",
            width: "60%",
            // border:"solid"
          }}
        >
          {[...data, 1].map((data, index, arr) => {
            const lastElement = index === arr.length - 1;
            return (
              <>
                {lastElement ? (
                  <div
                    style={{
                      width: "48%",
                      borderRadius: "8px",
                      // border: "solid",
                      backgroundColor:
                        theme?.palette?.type === "dark" &&
                        theme.palette.background.default,

                      // backgroundColor: theme.palette.grey[100],
                    }}
                  >
                    <Typography
                      className={classes.typography}
                      style={{ padding: "21px 15px 17px 20px" }}
                    >
                      ESG Score
                    </Typography>
                    {/* <BlueBackgroundCard heading={"ESG Score"} /> */}
                    <div
                      style={{
                        // width: "48%",
                        borderTop: `1px solid ${theme.palette.grey[100]}`,
                        backgroundColor:
                          theme?.palette?.type !== "dark" &&
                          theme.palette.grey[100],
                      }}
                    >
                      <Typography
                        className={classes.typography2}
                        style={{
                          color: theme.palette.primary.contrastText,
                          paddingLeft: "20px",
                          fontSize: "24px",
                          fontWeight: 400,
                        }}
                      >
                        54/100
                      </Typography>
                      <Typography
                        className={classes.primaryColor}
                        style={{
                          fontSize: "14px",
                          fontWeight: 400,
                          // color: "#6F7170",
                          padding: "0px 10px 10px 21px",
                          fontFamily: "Lato",
                          lineHeight: "18px",
                        }}
                      >
                        The score reflects its commitment to ESG
                        responsibilities, with demonstrated progress in
                        sustainability initiatives and ethical business
                        practices, while also highlighting areas with room for
                        improvement in the broader sustainability landscape.
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "48%",
                      backgroundColor:
                        theme?.palette?.type === "dark" &&
                        theme.palette.background.default,
                      borderRadius: "8px",
                    }}
                  >
                    <TopicHeader topic={data.topic} score={data.score} />
                    {data.subTopic.map((sub) => (
                      <TopicData topic={sub.topic} score={sub.score} />
                    ))}
                  </div>
                )}
              </>
            );
          })}
          {/* <div style={{ width: "48%" }}>
            <BlueBackgroundCard heading={"ESG Score"} />
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: 600,
                color: "#242424",
                padding: "5px",
              }}
            >
              {esgScore?.toFixed(2)}%
            </Typography>
          </div> */}
        </div>
        <div
          style={{
            // height: "100%",
            // padding: "50px 20px 50px 10px",
            // border:"solid",
            width: "35%",
            marginRight: "15px",
          }}
        >
          <ScoreChart scores={riskEvaluator?.scores} />
          {riskEvaluator?.scores && (
            <ScoreChart scores={riskEvaluator?.scores} />
          )}
        </div>
      </div>

      <BlueBackgroundCard heading={"ESG Controversy Score (ESGC) Score"} />

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 0px",
        }}
      >
        <Paper
          style={{
            width: "23%",
            // padding: "15px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            // justifyContent:"center",
            alignSelf: "stretch",
            borderRadius: "8px",
            border:
              theme.palette.type === "dark" &&
              `1px solid ${theme.palette.grey[300]}`,

            // backgroundColor: "#FEFEFE",
          }}
          className={classes.backgroundColorPrimary}
          elevation={0}
        >
          <HeadingCard
            primaryHead={"Self Assessment Score"}
            hideBorder={true}
            // borderRadius={"8px"}
          />

          {/* <Divider
            className={classes.borderPrimary}
            style={{ width: "100%" }}
          /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "10px",
              width: "180px",
            }}
          >
            <img src={contro_1} alt="contr1" />
            <Typography>54/100</Typography>
          </div>
          <PercentageDoughnutChart
            color="#9ABD8B"
            emptyColor="#E0E0E01A"
            percentage={54}
          />
        </Paper>
        <Paper
          style={{
            width: "23%",
            // padding: "15px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            alignSelf: "stretch",
            borderRadius: "8px",
            border:
              theme.palette.type === "dark" &&
              `1px solid ${theme.palette.grey[300]}`,
            // backgroundColor: "#FEFEFE",
          }}
          className={classes.backgroundColorPrimary}
          elevation={0}
        >
          <HeadingCard
            primaryHead={"Online Footprint Score"}
            hideBorder={true}
          />

          {/* <Divider
            className={classes.borderPrimary}
            style={{ width: "100%" }}
          /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "10px",
              width: "180px",
            }}
          >
            <img src={contro_2} alt="contr1" />
            <Typography>38/100</Typography>
          </div>

          <PercentageDoughnutChart
            color="#ECA270"
            emptyColor="#E0E0E01A"
            percentage={38}
          />
        </Paper>
        <Paper
          className={classes.backgroundColorPrimary}
          style={{
            width: "47%",
            // padding: "15px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            alignSelf: "stretch",
            borderRadius: "8px",
            border:
              theme.palette.type === "dark" &&
              `1px solid ${theme.palette.grey[300]}`,
            // backgroundColor: "#FEFEFE",
          }}
          elevation={0}
        >
          <HeadingCard primaryHead={"Reference Links"} hideBorder={true} />

          {/* <Divider
            className={classes.borderPrimary}
            style={{ width: "100%" }}
          /> */}

          <RefrenceLinkList linkArr={linkData} />
        </Paper>
      </div>

      <BlueBackgroundCard
        heading={"ESG KPI Data Breakdown With Data Representation"}
      />

      <div style={{ padding: "15px 0px 0px 0px" }}>
        <HeadingCard primaryHead={"Energy"} type={theme.palette.type}>
          <IconButton
            variant="outlined"
            className={classes.iconBackground}
            style={{
              // border: "1px solid #3E8DDD",
              // backgroundColor: "#FFFFFF",
              marginLeft: "10px",
              padding: "5px",
              borderRadius: "200px",
            }}
            size="small"
          >
            <MoreVertIcon className={classes.secondaryColorMain} />
          </IconButton>
        </HeadingCard>

        <KpiPresentation
          chartsArr={[
            "Total Energy Renewable (MegaJoules)",
            "Total Energy Non Renewable (MegaJoules)",
            "Total Other Energy Consumption Renewable",
          ]}
          row={energyRows}
        />
      </div>

      <div style={{ padding: "15px 0px 0px 0px" }}>
        <HeadingCard primaryHead={"Water"} type={theme.palette.type}>
          <IconButton
            variant="outlined"
            className={classes.iconBackground}
            style={{
              // border: "1px solid #3E8DDD",
              // backgroundColor: "#FFFFFF",
              marginLeft: "10px",
              padding: "5px",

              borderRadius: "200px",
            }}
            size="small"
          >
            <MoreVertIcon className={classes.secondaryColorMain} />
          </IconButton>
        </HeadingCard>
        <KpiPresentation
          chartsArr={["Total Volume of Water Consumption"]}
          row={waterRows}
          width={"67%"}
          headerRow={defaultRow1}
        />
      </div>
      <div style={{ padding: "15px 0px 0px 0px" }}>
        <HeadingCard primaryHead={"Waste"} type={theme.palette.type}>
          <IconButton
            variant="outlined"
            className={classes.iconBackground}
            style={{
              // border: "1px solid #3E8DDD",
              // backgroundColor: "#FFFFFF",
              marginLeft: "10px",
              padding: "5px",

              borderRadius: "200px",
            }}
            size="small"
          >
            <MoreVertIcon className={classes.secondaryColorMain} />
          </IconButton>
        </HeadingCard>
        <KpiPresentation
          chartsArr={[
            "Total Plastic Waste Recycling",
            "Total E-Waste Recycling",
            "Total Hazardous Waste Recycling",
          ]}
          width={"60%"}
          hide={true}
        />
      </div>
      {/* <BlueBackgroundCard heading={"ESG Insights"} />

      <EsgInsightMain title={"ESG Risk Area"} area={riskArea} color={"#E1140A"}>
        <ReportProblemOutlinedIcon
          style={{ color: theme.palette.primary }}
          fontSize="small"
        />
      </EsgInsightMain>
      <EsgInsightMain
        title={"Strong Area"}
        area={strongAreas}
        color={"#6AC346"}
      >
        <CheckOutlinedIcon
          style={{ color: theme.palette.primary }}
          fontSize="small"
        />
      </EsgInsightMain>
      <EsgInsightMain
        title={"Improvement Area"}
        area={improvementAreas}
        color={"#3E8DDD"}
      >
        <KeyboardArrowUpOutlinedIcon
          style={{ color: theme.palette.primary }}
          fontSize="small"
        />
      </EsgInsightMain> */}

      <BlueBackgroundCard heading={"Peer Analysis"} />

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          marginTop: "10px",
        }}
      >
        <Paper
          className={classes.backgroundColorPrimary}
          elevation={0}
          style={{ width: "65%" }}
        >
          {/* <CustomRange
            score={final_esg_score?.score.toFixed(2)}
            lowerRange={min_esg_score?.score?.toFixed(2)}
            upperRange={max_esg_score?.score?.toFixed(2)}
          /> */}
          <CustomCompaniesRange
            hasTitle={false}
            // fullwidth={false}
            // peer_companies={peer_companies}
            // score={final_esg_score?.score.toFixed(2)}
            // lowerRange={min_esg_score?.score?.toFixed(2)}
            // upperRange={max_esg_score?.score?.toFixed(2)}
          />
        </Paper>
        <div style={{ width: "35%", alignSelf: "flex-start" }}>
          <Typography
            style={{
              paddingLeft: 15,
              fontWeight: 700,
              marginTop: "2px",
              fontSize: "14px",
              // color: "#333F48",
              fontFamily: "Lato",
            }}
            className={classes.typoColorSecondary}
          >
            Based on our market insights and benchmarking tool,
          </Typography>
          <Typography
            className={classes.typoColorSecondary}
            style={{
              paddingLeft: 15,
              // color: "#333F48",
              fontWeight: 700,
              fontSize: "14px",
              marginTop: "14px",
              fontFamily: "Lato",
            }}
          >
            {` Your company is in the
                  ${
                    final_esg_score?.score <= 25
                      ? "first Quartile"
                      : final_esg_score?.score > 25 &&
                        final_esg_score?.score <= 50
                      ? "second quartile"
                      : final_esg_score?.score > 50 &&
                        final_esg_score?.score < 75
                      ? "third Quartile"
                      : "fourth quartile"
                  }
                  of the Peer group range and has significant scope to improve.`}
          </Typography>
          <Typography
            style={{
              paddingLeft: 15,
              fontWeight: 700,
              marginTop: "12px",
              fontSize: "14px",
              // color: "#333F48",
              fontFamily: "Lato",
            }}
            className={classes.typoColorSecondary}
          >
            Our curated sustainability solutions are designed to help you
            progress your company’s sustainability goals to the next level.
          </Typography>
        </div>
        <div style={{ width: "100%" }}>
          {[
            "“Good sustainability practices positively influenced the stock price performance of 80% of companies and lowered the cost of capital of 90% of companies. Solid ESG practices are found to have resulted in better operational performance in 88% of companies”",
          ].map((topic) => {
            return (
              <Typography
                key={topic}
                className={classes.primaryColor}
                style={{
                  paddingLeft: 15,
                  fontSize: "13px",
                  // color: "#333F48",
                  marginTop: "15px",
                  fontFamily: "Lato",
                  fontWeight: 400,
                }}
              >
                {topic}
              </Typography>
            );
          })}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          flexWrap: "wrap",
          marginTop: "15px",
        }}
      >
        <div style={{ width: "100%", height: "400px" }}>
          <Charts isPeer={true} />
        </div>
      </div>

      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 600,
            color: "#242424",
            padding: "15px",
          }}
        >
          Would you like to share these data
        </Typography>
        <MoreActionButtons />
      </div> */}

      <Paper
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          backgroundColor: theme.palette.grey[100],
          flexDirection: "column",
          // padding: "15px",
          marginTop: "10px",
        }}
        elevation={0}
      >
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 700,
            // color: "#242424",
            padding: "15px",
            fontFamily: "Lato",
            alignSelf: "center",
            textTransform: "capitalize",
          }}
          className={classes.typoColorSecondary}
        >
          Select from the below prompts about the company Or type in your
          question
        </Typography>
      </Paper>
      <div style={{ padding: "15px 0px 0px 0px" }}>
        <ChatPlayGround />
      </div>
    </Paper>
  );
}

export default EsgGptCompanyRight;
